import { Injectable } from '@angular/core'
import { Subject, Observable, BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private incomingNotification = new Subject<object>()
  public notificationToShow$: Observable<object>
  private notificationSubject = new BehaviorSubject<any[]>([])

  constructor() {
    this.notificationToShow$ = this.notificationSubject.asObservable()
    this.incomingNotification.subscribe((notification) => {
      const currentNotifications = this.notificationSubject.getValue()
      this.notificationSubject.next([...currentNotifications, notification])
    })
  }

  public notify(notification: any) {
    this.incomingNotification.next(notification)
  }
}
