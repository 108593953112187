import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable()
export class SnackBarService {
  constructor(private _snackBar: MatSnackBar) {}

  success(title: string) {
    this._snackBar.open(title, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 2000,
      panelClass: 'success-toast',
    })
  }

  error(title: string) {
    this._snackBar.open(title, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'error-toast',
    })
  }

  n67(ttl) {
    this._snackBar.open(ttl, 'Cerrar', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 5000,
    })
  }
}
