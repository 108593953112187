import { Component, HostBinding, OnInit } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { ThemeService } from './shared/services/theme.service'
import { NotificationService } from './shared/services/notification.service'
// import { Socket } from 'ngx-socket-io'
// import { connectSocket } from './shared/functions/connectSocket'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    private notificationService: NotificationService // private socket: Socket
  ) {
    if (themeService.getMode()) this.changeTheme('dark-theme')
  }

  @HostBinding('class') componentCssClass

  changeTheme(theme: 'dark-theme' | 'light-theme') {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) =>
      item.includes('-theme')
    )
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove)
    }
    overlayContainerClasses.add(theme)
    this.componentCssClass = theme
  }

  ngOnInit() {
    // connectSocket(this.socket, this.notificationService)
    // this.themeService.modeUpdated.subscribe((dark) =>
    //   dark ? this.changeTheme('dark-theme') : this.changeTheme('light-theme')
    // )
  }
}
